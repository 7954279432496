// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-post-js": () => import("./../src/components/post.js" /* webpackChunkName: "component---src-components-post-js" */),
  "component---src-components-category-js": () => import("./../src/components/category.js" /* webpackChunkName: "component---src-components-category-js" */),
  "component---src-components-mainlist-js": () => import("./../src/components/mainlist.js" /* webpackChunkName: "component---src-components-mainlist-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

